<template>
  <div class="spot-map position-relative h-100 w-100">
    <transition name="fade" mode="out-in">
      <loading-overlay v-if="loading" />
    </transition>
    <transition name="slide-left" mode="out-in">
      <div
        v-if="showSidebar"
        class="lateral-side-bar position-absolute shadow rounded bg-white h-100"
      >
        <div class="d-flex flex-column px-3 pb-4">
          <h4 class="text-dark-darken font-weight-bold">
            {{ titleText }}
          </h4>
          <transition name="fade" mode="out-in">
            <uello-button
              color="black"
              key="prev-button"
              icon="chevron-left"
              v-show="activeIndex > 0"
              @click="activeIndex -= 1"
              text="Voltar ao passo anterior"
            />
          </transition>
        </div>
        <div class="d-flex flex-column">
          <stepper-component :activeIndex="activeIndex" :steps="steps" :subHeight="400">
            <transition :name="slideMode" mode="out-in">
              <div v-if="activeIndex === 0" :key="`slot-${0}`">
                <first-step
                  title="Pedido"
                  :editInfo="editInfo"
                  :sourceData="form.source"
                  :destinationData="form.destination"
                  @source="setSourceAddress"
                  @destination="setDestinationAddress"
                  @next-step="firstActive = true"
                />
              </div>
              <div v-if="activeIndex === 1" :key="`slot-${1}`">
                <second-step
                  ref="second-step"
                  title="Dados do Cliente"
                  :customer="this.form.customer"
                  :formTextbox="formTextbox"
                  @is-sendable="setIsSendable"
                  @next-step="setCustomerData"
                />
              </div>
              <div v-if="activeIndex === 2" :key="`slot-${2}`">
                <third-step
                  title="Volumes"
                  :packSize="packSize"
                  :nfeKey="nfeKey"
                  :volumeInfo="{ q_vol: form.q_vol, volume: form.volume }"
                  :identifiers="form.identifiers"
                  :boxSizes="sizes"
                  @next-step="setDeliveryData"
                />
              </div>
              <div v-if="activeIndex === 3" :key="`slot-${3}`">
                <fourth-step
                  title="Resumo do pedido"
                  :customer="form.customer"
                  :source="form.source"
                  :destination="form.destination"
                  :boxSizes="boxSizes"
                  :price="price"
                  :distance="distance"
                  :eta="eta"
                  :volumes="form.q_vol"
                  @edit="setEditInfo"
                />
              </div>
            </transition>
          </stepper-component>
          <div class="d-flex justify-content-between align-items-center w-100 px-3 mt-2">
            <uello-button color="white" @click="cancelSolicitation" text="Cancelar Solicitação" />
            <uello-button
              color="red"
              v-if="!isLast"
              :disabled="nextEnabled"
              @click="nextStep"
              :text="$t('spot.nextButton')"
            />
            <uello-button v-else color="red" size="small" @click="handleSubmit" text="Enviar" />
          </div>
        </div>
      </div>
    </transition>
    <mgl-map
      ref="map"
      :accessToken="mapbox.accessToken"
      :mapStyle="mapbox.mapStyle"
      :center="mapbox.center"
      :zoom="mapbox.zoom"
      @load="changeLogoHref"
      class="w-100 h-100"
    >
      <mgl-marker
        v-for="(marker, index) in markers"
        :coordinates="[marker.longitude, marker.latitude]"
        :key="`marker-${index}`"
      >
        <map-marker slot="marker" color="var(--dark-info)" :orderNumber="index + 1" />
      </mgl-marker>
    </mgl-map>
  </div>
</template>

<script>
import { MglMap, MglMarker, MglPopup } from 'vue-mapbox';

import { mapActions } from 'vuex';
import { getGeoLocation, monetaryFormat, numberFormat, safeStorage } from '@/utils';
import api from '@/services/api';
import * as types from '@/store/types';
import { UelloButton } from '@uello/componentello';
import MapMarker from '../u-map/MapMarker.vue';

const { mapbox_key } = safeStorage.getItem('config');
export default {
  name: 'spot-map',
  props: {
    operationType: {
      type: String,
      default: () => 'spot',
    },
    formTextbox: {
      type: Boolean,
      default: () => false,
    },
    operation: {
      type: Object,
      default: () => ({}),
    },
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    MglMap,
    MglMarker,
    MglPopup,
    MapMarker,
    AddressForm: () => import('./AddressForm.vue'),
    FirstStep: () => import('./FirstStep.vue'),
    SecondStep: () => import('./SecondStep.vue'),
    ThirdStep: () => import('./ThirdStep.vue'),
    FourthStep: () => import('./FourthStep.vue'),
    LoadingOverlay: () => import('../u-follow/FollowAgGridLoadingOverlay.vue'),
    UelloButton,
  },
  data() {
    return {
      form: {
        source: {
          address: '',
          number: '',
          complement: '',
          reference: '',
          postcode: '',
          neighborhood: '',
          city: '',
          uf: '',
          geoLocation: {
            latitude: 0,
            longitude: 0,
          },
        },
        destination: {
          address: '',
          number: '',
          complement: '',
          reference: '',
          postcode: '',
          neighborhood: '',
          city: '',
          uf: '',
          geoLocation: {
            latitude: 0,
            longitude: 0,
          },
        },
        nfe_value: 0,
        identifiers: [],
        items: [],
        q_vol: 0,
        volume: 0.0,
        weight: 0,
        customer: {
          name: '',
          document: '',
          email: '',
          phone: '',
        },
      },
      sizes: [],
      map: null,
      loading: true,
      activeIndex: 0,
      editInfo: null,
      isSendable: false,
      firstActive: false,
      step: 0,
      boxSizes: [],
      packSize: '1',
      nfeKey: '1',
      price: '',
      distance: '',
      eta: '',
      source: {
        address: '',
        number: '',
        complement: '',
        reference: '',
        postcode: '',
        neighborhood: '',
        city: '',
        uf: '',
        geoLocation: {
          latitude: 0,
          longitude: 0,
        },
      },
      destination: {
        address: '',
        number: '',
        complement: '',
        reference: '',
        postcode: '',
        neighborhood: '',
        city: '',
        uf: '',
        geoLocation: {
          latitude: 0,
          longitude: 0,
        },
      },
      slideMode: 'slide-left',
      mapbox: {
        accessToken: mapbox_key,
        mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
        center: [-46.6329973, -23.5506117],
        zoom: 10,
      },
      markers: [],
      showSidebar: false,
      steps: [
        {
          icon: 'map-marker',
          id: 0,
          text: this.$t('stepper.order'),
        },
        {
          icon: 'account',
          id: 2,
          text: this.$t('stepper.customer'),
        },
        {
          icon: 'package-variant-closed',
          id: 1,
          text: this.$t('stepper.delivery'),
        },
        {
          icon: 'check',
          id: 3,
          text: this.$t('stepper.resume'),
        },
      ],
    };
  },
  watch: {
    activeIndex(value, oldValue) {
      if (value < oldValue) {
        this.slideMode = 'slide-left';
      } else {
        this.slideMode = 'slide-right';
      }
    },
    markers(value) {
      if (value.length === 2) {
        const [from, to] = value;
        const add1 = [from.longitude, from.latitude];
        const add2 = [to.longitude, to.latitude];

        this.$refs.map.map.fitBounds([add1, add2], {
          padding: {
            top: 300,
            left: 500,
            bottom: 300,
            right: 50,
          },
        });
      }
    },
  },
  computed: {
    nextEnabled() {
      switch (this.activeIndex) {
        case 0:
          return !(
            this.source !== null &&
            this.source.geoLocation.latitude !== 0 &&
            this.destination !== null &&
            this.destination.geoLocation.latitude !== 0
          );
        case 1:
          return !this.isSendable;
        case 2:
          return !(this.form.q_vol > 0);
        default:
          return true;
      }
    },
    isLast() {
      return this.activeIndex === this.steps.length - 1;
    },
    titleText() {
      const key = this.formTextbox ? 'requestItems.formTextboxFormTitle' : 'pageTitles.spotRoute';
      return this.$t(key);
    },
  },
  methods: {
    ...mapActions({
      setChosenOperation: types.ACTION_CHOSEN_OPERATIONS,
    }),
    changeLogoHref() {
      this.loading = false;
      const linkContainer = document.querySelector('.mapboxgl-ctrl');
      const link = document.querySelector('a.mapboxgl-ctrl-logo');
      link.href = 'https://uello.com.br';
      link.title = 'Uello';
      linkContainer.style.zIndex = 10000000000;

      this.changeLogoPosition();
      this.showSidebar = true;
      getGeoLocation(coodinates => {
        const [longitude, latitude] = coodinates;
        this.mapbox.center = [longitude - 0.15, latitude];
      });
    },
    changeLogoPosition() {
      const left = document.querySelector('.mapboxgl-ctrl-bottom-left').innerHTML;
      const right = document.querySelector('.mapboxgl-ctrl-bottom-right');
      right.innerHTML = left;
    },
    setSourceAddress(data) {
      this.step = 2;
      this.source = data;
      this.form.source = data;
      this.markers = this.markers.length
        ? [data.geoLocation, this.markers[this.markers.length - 1]]
        : [data.geoLocation];
    },
    setDestinationAddress(data) {
      this.step = 0;
      this.destination = data;
      this.form.destination = data;
      this.markers = this.markers.length ? [this.markers[0], data.geoLocation] : [data.geoLocation];
    },
    setCustomerData(data, nfeKey) {
      this.form.customer = data;
      this.form.nfe_value = data.nfe_value;
      this.nfeKey = nfeKey;
    },
    setDeliveryData(data) {
      this.form.q_vol = data.q_vol;
      this.form.volume = data.volume;
      this.form.identifiers = data.identifiers;
      this.form.weight = data.weight;
      this.boxSizes = data.sizesCount;
      this.sizes = data.sizes;
      this.form.items = data.identifiers.map((item, index) => {
        return {
          id: index + 1,
          identifier: item,
          weight: data.weights[index],
          volume: data.volumes[index],
        };
      });
    },
    nextStep() {
      if (this.isSendable && this.activeIndex === 1) {
        this.$refs['second-step'].handleSubmit();
        document.querySelector('#stepper-scroll-bar').scrollTo(0, 0);
      }

      if (this.activeIndex === 2) {
        this.setPrice();
        document.querySelector('#stepper-scroll-bar').scrollTo(0, 0);
      } else {
        this.activeIndex += 1;
        document.querySelector('#stepper-scroll-bar').scrollTo(0, 0);
      }
    },
    setIsSendable(value) {
      this.isSendable = value;
    },
    async setPrice() {
      try {
        this.loading = true;
        const { source, destination } = this.form;
        const { volume, q_vol } = this.form;
        const { weight } = this.form;
        const { nfe_value } = this.form;
        const { longitude: longS, latitude: latS } = source.geoLocation;
        const { longitude: longD, latitude: latD } = destination.geoLocation;

        const data = {
          source: {
            postcode: source.postcode,
            latitude: latS,
            longitude: longS,
          },
          destination: {
            postcode: destination.postcode,
            latitude: latD,
            longitude: longD,
          },
          volume,
          q_vol,
          weight,
        };

        if (this.operation && this.operation.cnpj && this.operation.operation) {
          data.partner = this.operation.cnpj;
          data.operation = this.operation.operation;
          data.is_partner_child = this.operation.is_partner_child;
        }

        const { data: response } = await api.post('/orders/price', data);

        const { price, distance, eta } = response.data;

        this.price = monetaryFormat(price + Number(nfe_value));
        this.distance = this.distance ? `${numberFormat(distance)}km` : '-';
        this.eta = this.eta > 1 ? `${eta} min` : '-';

        this.activeIndex += 1;
      } catch (error) {
        this.$toast.error(this.$t('messages.getPrice'));
      }

      this.loading = false;
    },
    setEditInfo(info) {
      document.querySelector('#stepper-scroll-bar').scrollTo(0, 0);

      if (info < 3) {
        this.editInfo = info;
        this.activeIndex = 0;
      } else {
        switch (info) {
          case 3:
            this.activeIndex = 1;
            break;
          case 4:
            this.activeIndex = 2;
            break;
          default:
        }
      }
    },
    async handleSubmit() {
      try {
        this.loading = true;
        // const { document: cnpj, operations } = JSON.parse(
        //   localStorage.getItem('@portalpartner:config')
        // );
        // const { id: operation } = operations.find(op => op.type === this.operationType);

        const { id: operationId, operation, cnpj } = this.operation;
        const {
          address,
          number,
          complement,
          reference,
          postcode,
          neighborhood,
          city,
          uf,
          geoLocation: geolocation,
        } = this.form.destination;

        const {
          address: pickup_address,
          number: pickup_number,
          complement: pickup_complement,
          reference: pickup_reference,
          postcode: pickup_postcode,
          neighborhood: pickup_neighborhood,
          city: pickup_city,
          uf: pickup_uf,
          geoLocation: pickup_geolocation,
        } = this.form.source;

        const data = {
          items: [
            {
              ...this.form.customer,
              weight: parseFloat(this.form.volume),
              q_vol: this.form.q_vol,
              identifier: this.form.identifiers,
              items: this.form.items,
              address,
              number,
              complement,
              reference,
              postcode,
              neighborhood,
              city,
              uf,
              geolocation,
              pickup_address,
              pickup_number,
              pickup_complement,
              pickup_reference,
              pickup_postcode,
              pickup_neighborhood,
              pickup_city,
              pickup_uf,
              pickup_geolocation,
              operation: operationId || operation,
              cnpj,
              operation_type: this.operationType,
            },
          ],
        };

        await api.post('/orders', data);
        this.loading = false;
        this.$router.push('/app/follow');
      } catch (error) {
        this.loading = false;
        this.$toast.error(this.$t('messages.spotCreateError'));
      }
    },
    cancelSolicitation() {
      if (!this.formTextbox) {
        this.$router.push('/app/request');
      } else {
        this.$emit('cancel-solicitation');
      }
    },
  },
  mounted() {
    this.setChosenOperation(this.operation);
  },
};
</script>

<style lang="scss">
@import url('https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css');
.spot-map {
  .order-forms {
    svg {
      transform: scale(0.9);
    }
  }

  [role='tab-list'].border-bottom {
    border-width: 2px !important;
    border-color: var(--transition) !important;
  }

  // slide-left
  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: all 300ms ease;
  }
  .slide-left-enter {
    transform: translateX(-400px);
  }

  .slide-left-leave-to {
    transform: translateX(400px);
  }

  // slide-right
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: all 300ms ease;
  }
  .slide-right-enter {
    transform: translateX(400px);
  }

  .slide-right-leave-to {
    transform: translateX(-400px);
  }

  .lateral-side-bar {
    width: 400px;
    max-width: 100%;
    z-index: 10;
    top: 0;
    left: 0;
  }

  .mapboxgl-control-container {
    a.mapboxgl-ctrl-logo {
      background: url('../../../../assets/images/logo-uello-complete-powered-by.png') center center !important;
      background-size: cover !important;
      width: 150px !important;
      height: 40px !important;
    }

    .mapboxgl-ctrl-bottom-left {
      display: none !important;
    }
  }
}
</style>
